<template>
  <div class="container-fluid">
    <vue-qrcode-reader @postQRCode="postQRCode" class="camera" ref="qrcodeReader" />

    <!-- setting -->
    <div class="card justify_content_end">
      <div class="icon" @click="goToRoute('msg')">
        <div class="icon_badge" v-if="formatNum > 0 && formatNum !== undefined">
          <div>{{ formatNum }}</div>
        </div>
        <img src="../../../assets/images/public/ic_mine_bells.webp" />
      </div>
      <div class="icon" @click="goToRoute('setting')">
        <img src="../../../assets/images/public/ic_mine_set_up.webp" />
      </div>
    </div>
    <!-- user -->
    <div class="card mb_2em justify_content_between" v-if="usersData">
      <div class="d_flex" @click="setUserInfo">
        <div class="head_image">
          <img :src="avatar" alt="" />
        </div>
        <div class="text_left text_white">
          <p v-if="nickName">{{ nickName }}</p>
          <small class="text_small" v-if="userMobile">{{ interceptAndReplace(userMobile, 3, 4) }}</small>
        </div>
      </div>
      <div class="icon_scanner" @click="handleOpenCamera">
        <!-- <VueQrcodeReader @postQRCode="postQRCode" @onFullscreenChange="onFullscreenChange" @error="getQRCodeError" class="camera" /> -->

        <img src="../../../assets/images/public/ic_scan_code.webp" />
      </div>
    </div>
    <div class="container_content" :class="{ d_none: isOpenCamera }">
      <div class="card mb_1em bg_light">
        <div class="card_body">
          <div class="d_flex justify_content_between mb_1em">
            <div>账户余额</div>
            <div>免手续费额度</div>
            <div>账户积分</div>
          </div>
          <div class="d_flex justify_content_between">
            <div class="text_large text_primary font_weight_bold">
              {{ commission }}
            </div>
            <div class="text_large text_primary font_weight_bold">
              {{ withdrawCommission }}
            </div>
            <div class="text_large text_secondary">{{ integral }}</div>
          </div>
        </div>
      </div>
      <div class="card mb_1em bg_light" v-for="(list, i) in lists" :key="i" :class="{ d_none: isOpenCamera }">
        <div class="w_100 px_25rem">
          <BaseItem v-for="(item, j) in list" :key="item.title" :title="item.title" :imageUrl="item.icon" :borderBottom="j < list.length - 1" :path="item.path" :openBlankPage="item.openBlankPage" @open="openQQModal" @click="handleItemClick(item)" />
        </div>
      </div>
    </div>
    <!-- Modal -->
    <Modal content="是否放弃本次付款?" header="" :closeModal="closeCheckModal">
      <div slot="footer" class="btn_group">
        <button class="btn_default btn_secondary" @click="cancelHandler">
          放弃
        </button>
        <button class="btn_default btn_primary" @click="checkHandler">
          继续付款
        </button>
      </div>
    </Modal>

    <!-- 重设密码 -->
    <ResetPaymentPassword v-if="isOpenResetPaymentPasswordModal" @closeModal="closeResetPaymentPasswordModal"> </ResetPaymentPassword>

    <!-- PaymentModal 付款详情 -->
    <PaymentModal
      v-if="ordersData"
      :isShow="isShowModal"
      :orderAmount="ordersData.orderAmount"
      :merchantName="ordersData.merchantName"
      :orderNo="ordersData.orderNo"
      :userMobile="ordersData.userMobile"
      :isShowData="isShowData"
      :hasMarginTop="!isOpenResetPaymentPasswordModal"
      :responseMsg="responseMsg"
      :isSecurityCode="usersData.isSecurityCode"
      @close="closeModal"
      @updatePassword="updatePassword"
      @openResetModal="openResetModal"
      ref="PaymentModal"
    />

    <!-- Success Modal 支付成功   -->
    <SuccessModal v-if="ordersData && ordersData.amount" :isShowSuccessModal="isShowSuccessModal" :amount="ordersData.amount" :integral="ordersData.integral" @close="closeSuccessModal"></SuccessModal>

    <!-- QQModal -->
    <QQModal :closeModal="closeQQ" @close="closeQQModal"></QQModal>

    <FooterMenu v-if="!isShowModal" class="footer" :class="{ d_none: isOpenCamera }"> </FooterMenu>
    <!-- AI客服 iframe 模態框 -->
    <div v-if="isAIModalOpen" class="modal-container">
      <div class="modal-content">
        <button class="close-btn" @click="closeAIService">X</button>
        <iframe
          :src="aiServiceUrl"
          class="iframe-service"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { mapState, mapActions } from "vuex";
import VueQrcodeReader from "@/components/VueQrcodeReader.vue";
import BaseItem from "../components/BaseItem.vue";
import Modal from "../components/Modal.vue";
import PaymentModal from "../components/PaymentModal.vue";
import SuccessModal from "../components/SuccessModal.vue";
import myPayment from "@/assets/images/public/ic_mine_fund_log.webp";
import paymentMethod from "@/assets/images/public/ic_mine_pay_type.png";
import pointsDetails from "@/assets/images/public/ic_mine_integral.png";
import myCollection from "@/assets/images/public/ic_mine_collect.webp";
import securityCenter from "@/assets/images/public/ic_mine_security_center.webp";
import aboutUs from "@/assets/images/public/ic_mine_about_us.webp";
import onlineService from "@/assets/images/public/ic_mine_online_service.webp";
// import QQservice from "@/assets/images/public/ic_mine_qq_service.png";
import helpCenter from "@/assets/images/public/ic_mine_help_center.webp";
import FooterMenu from "@/components/FooterMenu";
import QQModal from "../components/QQModal.vue";
import avatar from "@/assets/images/public/ic_my_head.webp";
import ResetPaymentPassword from "./resetPaymentPassword.vue";
import { getUserConfig } from "@/api/config.js";
import AIservice from "@/assets/images/public/ic_mine_ai_service.png";

export default {
  name: "my",
  components: {
    BaseItem,
    FooterMenu,
    VueQrcodeReader,
    Modal,
    PaymentModal,
    SuccessModal,
    QQModal,
    ResetPaymentPassword,
  },
  computed: {
    ...mapState("users", ["usersData"]),
    ...mapState("orders", ["ordersData"]),
    ...mapState("spinner", ["isLoading"]),
    ...mapState("msg", ["num"]),
    avatar() {
      return this.usersData && this.usersData.avatar !== "" ? this.usersData.avatar : avatar;
    },
    nickName() {
      return this.usersData ? this.usersData.nickName : "昵称";
    },
    userMobile() {
      return this.usersData ? this.usersData.userMobile : "手机号";
    },
    commission() {
      // 账户余额
      return this.usersData ? this.usersData.commission : 0;
    },
    withdrawCommission() {
      // 免手续费额度
      return this.usersData ? this.usersData.withdrawCommission : 0;
    },
    integral() {
      // 账户积分
      return this.usersData ? this.usersData.integral : 0;
    },
    formatNum() {
      return this.num < 100 ? this.num : 99 + "+";
    },
  },
  watch: {
    ordersData(newVal) {
      if (newVal) {
        this.isShowData = true;
        this.changeSpinnerStatus(false);
      } else {
        this.changeSpinnerStatus(false);
      }
    },
    $route: {
      handler: function(to) {
        if (to.query.orderNo !== undefined && to.query.orderNo !== "") {
          this.init(to.query.orderNo);
        } else {
          this.changeSpinnerStatus(false);
        }
      },
      immediate: true,
    },
    responseMsg(newVal) {
      if (newVal) {
        this.changeSpinnerStatus(false);
      }
    },
  },
  data() {
    return {
      closeQQ: true,
      isOpenCamera: false,
      isShowModal: false,
      isShowSuccessModal: false,
      isOpenResetPaymentPasswordModal: false,
      isShowData: false,
      closeCheckModal: true,
      orderNo: "",
      responseMsg: "",
      isAIModalOpen: false, // 控制模態框顯示
      aiServiceUrl: "",
      lists: [
        [
          {
            icon: myPayment,
            title: "我的账单",
            path: "/my/myPayment",
            openBlankPage: false,
          },
          {
            icon: paymentMethod,
            title: "收款方式",
            path: "/my/paymentMethod",
            openBlankPage: false,
          },
          {
            icon: pointsDetails,
            title: "积分明细",
            path: "/my/pointsDetails",
            openBlankPage: false,
          },
        ],
        [
          {
            icon: myCollection,
            title: "我的收藏",
            path: "/my/myCollection",
            openBlankPage: false,
          },
          {
            icon: securityCenter,
            title: "安全中心",
            path: "/my/securityCenter",
            openBlankPage: false,
          },
          {
            icon: aboutUs,
            title: "关于我们",
            path: "/my/aboutUs",
            openBlankPage: false,
          },
        ],
        [
          {
            icon: onlineService,
            title: "在线客服",
            path: "",
            openBlankPage: true,
          },
          // {
          //   icon: QQservice,
          //   title: "QQ客服",
          //   path: "QQservice",
          //   openBlankPage: false,
          // },
          {
            icon: AIservice,
            title: "AI客服",
            path: "https://cloudpay-cs.33dsp.com/",
            openBlankPage: true,
          },
          {
            icon: helpCenter,
            title: "帮助中心",
            path: "/my/helpCenter",
            openBlankPage: false,
          },
        ],
      ],
    };
  },
  methods: {
    ...mapActions("orders", ["getOrders", "updateOnlinePay"]),
    ...mapActions("spinner", ["changeSpinnerStatus"]),
    ...mapActions("users", ["getUsers"]),

    openQQModal() {
      this.closeQQ = false;
    },
    closeQQModal() {
      this.closeQQ = true;
    },
    toggleCamera() {
      this.isOpenCamera = true;
    },
    handleOpenCamera() {
      this.$refs.qrcodeReader.toggleScan();
    },
    interceptAndReplace(str, frontLen, endLen, symbol = "*") {
      if (str.length != 11) {
        return str;
      }
      var len = str.length - frontLen - endLen;
      var xing = "";
      for (var i = 0; i < len; i++) {
        xing += symbol;
      }
      return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
    },
    setUserInfo() {
      this.$router.push({ path: "/my/userInfo" });
    },
    goToRoute(route) {
      this.$router.push({ path: `/my/${route}` });
    },
    postQRCode(QRCode) {
      if (QRCode) {
        if (QRCode.includes("orderNo")) {
          const url = new URL(QRCode);
          const orderNo = url.hash.split("=")[1];
          this.$router.push({ path: "/my/index", query: { orderNo: orderNo } });
        } else {
          Message({
            message: "無效訂單編號",
            iconClass: "x",
            center: true,
            customClass: "error_message",
            duration: 1500,
            onClose: () => {
              this.isOpenCamera = false;
              return;
            },
          });
        }
      } else {
        Message({
          message: "無效訂單編號",
          iconClass: "x",
          center: true,
          customClass: "error_message",
          duration: 1500,
          onClose: () => {
            this.isOpenCamera = false;
            return;
          },
        });
      }
    },

    onFullscreenChange() {
      this.isOpenCamera = false;
    },
    cancelHandler() {
      this.closeCheckModal = true;
      this.isShowModal = false;
      this.onFullscreenChange();
      this.changeSpinnerStatus(false);
      this.resetPath();
    },
    checkHandler() {
      this.closeCheckModal = true;
    },
    closeModal() {
      this.closeCheckModal = false;
      this.onFullscreenChange();
    },
    closeSuccessModal() {
      this.isShowModal = false;
      this.isShowSuccessModal = false;
      this.closeCheckModal = true;
      this.onFullscreenChange();
      this.resetPath();
      // this.getUsers();
    },
    closeResetPaymentPasswordModal() {
      this.isOpenResetPaymentPasswordModal = false;
      this.onFullscreenChange();
    },
    updatePassword(securityCode) {
      if (!this.orderNo && !securityCode) return;
      this.onFullscreenChange();
      let data = {
        orderNo: this.orderNo,
        securityCode: securityCode,
      };
      this.changeSpinnerStatus(true);
      this.updateOnlinePay(data).then((res) => {
        if (res.code !== "ok") {
          Message({
            message: res.msg,
            iconClass: "x",
            center: true,
            customClass: "error_message",
          });
          this.$refs["PaymentModal"].resetInputs();
        } else {
          this.closeCheckModal = true;
          this.isShowSuccessModal = true;
        }
        this.changeSpinnerStatus(false);
      });
    },
    resetPath() {
      this.$router.replace({ path: "/my/index" });
    },
    getQRCodeError(error) {
      if (!error) return;
      this.isOpenCamera = false;
    },
    openResetModal() {
      this.isOpenResetPaymentPasswordModal = true;
    },
    init(orderNo) {
      this.isShowData = false;
      this.orderNo = "";
      if (orderNo !== null) {
        this.changeSpinnerStatus(true);
        this.isShowModal = true;
        this.orderNo = orderNo;
        this.getOrders({
          orderNo: orderNo,
        }).then((res) => {
          this.responseMsg = res.code !== "ok" ? res.msg : "";
        });
      }
    },
    // 打開 AI 客服模態框
    openAIService() {
      this.isAIModalOpen = true;
    },
    // 關閉 AI 客服模態框
    closeAIService() {
      this.isAIModalOpen = false;
    },
    handleItemClick(item) {
      // 針對 "AI客服" 處理特殊邏輯
      if (item.title === "AI客服") {
        this.openAIService(); // 開啟 AI 客服模態框
      }
    },
  },
  async created() {
    this.isOpenCamera = false;
    this.getUsers();
    var res = await getUserConfig();
    this.aiServiceUrl = res.data.aiCustomerUrl;
    this.lists[2][0].path = res.data.customerServiceUrl;
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative !important;
}

// container
.container-fluid {
  background: #fafafa;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  position: relative;
  padding: 2rem 1.2rem 0 1.2rem;
  box-sizing: border-box;

  &::after {
    content: "";
    display: block;
    top: -1%;
    left: 0%;
    right: 0;
    position: fixed;
    background-image: url("../../../assets/images/public/ic_mine_bg.webp");
    background-size: cover;
    background-position: bottom;
    height: auto;
    min-height: 15rem;
    // max-width: 414px;
    margin: 0 auto;

    @media (min-width: 480px) {
      max-width: unset;
    }
  }
}

.container_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 5rem);
  overflow-y: auto;
  // margin-bottom: 5rem;
}

.footer {
  z-index: 200;
}

.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.d_none {
  opacity: 0;
  display: none;
  z-index: -1;
}

.justify_content_end {
  justify-content: flex-end;
}

.justify_content_between {
  justify-content: space-between;
}

.card {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
  border-radius: 0.35rem;

  &_body {
    width: 100%;
    padding: 0.75rem;
  }
}

// text
.text_left {
  text-align: left;
}

.text_white {
  color: white;
}

.text_dark {
  color: var(--black);
}

.text_primary {
  color: var(--orange);
}

.text_secondary {
  color: var(--gray);
}

p {
  margin-bottom: 0.5rem;
}

.text_small {
  font-size: 0.85rem;
}

.text_large {
  font-size: 1.5rem;
}

.font_weight_bold {
  font-weight: bold;
}

// icon && images
.head_image {
  display: block;
  width: 3em;
  margin-right: 0.5rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.icon {
  display: block;
  position: relative;
  padding: 0.5rem;

  img {
    width: 45%;
    height: auto;
    object-fit: cover;
  }
}

.icon_badge {
  display: flex;
  min-width: 1.2rem;
  height: 1.2rem;
  background-color: #e44747;
  box-shadow: 1px 1px 2px #863333;
  border-radius: 1.2rem;
  color: #fff;
  position: absolute;
  padding: 0.25rem 0.35rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  left: 1.5rem;
  top: 0rem;
  transform: scale(0.75);

  div {
    font-size: 0.75rem;
    transform: translateY(1px);
  }
}

.icon_scanner {
  display: block;
  padding: 0.5rem 1.2rem;
  margin-right: 2.75rem;
  position: relative;
  z-index: 9999;

  img {
    width: 2rem;
    height: auto;
    display: block;
    margin: 0 auto;
    object-fit: cover;
  }

  .camera {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
  }
}

// utiles
.w_100 {
  width: 100%;
}

.py_5rem {
  padding: 0.5em 0;
}

.bg_light {
  background-color: #fff;
}

.px_25rem {
  padding: 0rem 0.75rem;
}

.btn {
  &_group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  &_default {
    display: inline-block;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--light-gray);
    border: none;
    padding: 0.65rem 0rem;
    border-radius: 20px;
    margin: 0 0.5rem;
    font-size: 1rem;
    min-width: 40%;
  }

  &_primary {
    color: white;
    background-color: var(--orange);
  }

  &_secondary {
    color: white;
    background-color: var(--light-black);
  }
}
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.iframe-service {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}
</style>
