<template>
  <div class="integralExchange">
    <Header :hideGoBack="true" :title="title" theme="light" />
    <div class="container__main">
      <div
        class="wrap__content"
        v-for="(item, i) in productsData"
        :key="i"
        @click="
          $router.push({
            path: '/integralExchangeConfirm',
            query: {
              cardQuota: item['cardQuota'],
              price: item['price'],
              id: item['id']
            },
          })
        "
      >
        <div class="box__img" v-if="i % 2 != 1">
          <img
            src="../../assets/images/public/ic_points_mall_orange.webp"
            alt=""
          />
        </div>
        <div class="box__img" v-else>
          <img
            src="../../assets/images/public/ic_points_mall_red.webp"
            alt=""
          />
        </div>
        <div class="box__text">
          <div>¥</div>
          <div>{{ item.cardQuota }}</div>
          <div>{{ item.price }}积分兑换</div>
        </div>
      </div>
      <div v-if="signData.hasStarted == 1" class="sign__in">
        <div @click="showDailyBannerHandler">
          <p>立即签到</p>
        </div>
      </div>
      <template>
        <div v-if="showDailyBanner == true">
          <DailyCheckin @isPopupDailyBanner="isPopupDailyBanner" />
        </div>    
      </template>
    </div>
    <FooterMenu />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import { mapState, mapActions } from "vuex";
import DailyCheckin from "@/components/DailyCheckin.vue";
export default {
  name: "integralExchange",
  components: {
    Header,
    FooterMenu,
    DailyCheckin
  },
  data() {
    return {
      title: "积分活动",
      data: [],
      showDailyBanner: false,
    };
  },
  computed: {
    ...mapState("products", ["productsData"]),
    ...mapState("users", ["signData"]),
  },
  watch: {
    productsData(e) {
      this.data = e;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("products", ["getProducts"]),
    init() {
      this.getProducts();
    },
    showDailyBannerHandler() {
      console.log("Received show-daily-checkin event");
      this.showDailyBanner = true; // 將 showDailyBanner 設為 true，顯示 DailyCheckin 組件
    },
    isPopupDailyBanner(isPopupDailyBanner) {
      this.showDailyBanner = isPopupDailyBanner;
    },
  },
};
</script>

<style lang="scss" scoped>
.integralExchange {
  width: 100%;
  .container__main {
    height: calc(92vh - 72px);
    overflow-y: scroll;
    .wrap__content {
      width: 100%;
      position: relative;
        @media (min-width:480px){
          width: 50%;
        margin: auto;
        }

      &:nth-child(odd) {
        color: var(--orange);
      }
      &:nth-child(even) {
        color: var(--red);
      }
      .box__img {
        width: 100%;
        font-size: 0;
        img {
          width: 100%;
        }
      }
      .box__text {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        div {
        }
        div:nth-child(1) {
          align-self: start;
        }
        div:nth-child(2) {
          font-size: 3.5rem;
        }
        div:nth-child(3) {
          align-self: flex-end;
        }
      }
    }
  }
}
.sign__in {
  display: flex;
  justify-content: center;
  margin: 0.5rem;

  div {
    @media (min-width:480px){
      width: 50%;
    }
    padding: 0.5rem 1rem;
    background-color: #FF6C18;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    color: white;
  }
}
</style>
