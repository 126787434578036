<template>
  <div class="PopupDailyCheckin">
    <div class="container__close" @click="childClick()"></div>
    <div class="container__title">
      <div class="tw-flex tw-justify-end" @click="childClick()">
        <img class="tw-w-[24px] tw-h-[24px]" src="@/assets/images/public/sign_in_close.webp" alt="" />
      </div> 
      <div>
        <img class="container__banner" src="@/assets/images/public/sign_in_banner.webp" alt="" />
      </div>     
    </div>
    <div class="container__info">
      <div class="title">{{currentMonth}}月签到簿</div>
      <div class="tw-flex tw-justify-center tw-my-4 tw-whitespace-nowrap">
          <p class="tw-text-[22px] tw-font-semibold">已连续签到</p>
          <p class="tw-text-[22px] tw-text-[#FF6802] tw-font-semibold">{{signData.signedInDays}}</p>
          <p class="tw-text-[22px] tw-mr-[12%] tw-font-semibold">天</p>  

          <p class="tw-text-[16px] tw-text-[#828282]">下次签到可获得</p>
          <p class="tw-text-[16px] tw-text-[#FF6802]">{{signData.nextReward}}</p>
          <p class="tw-text-[16px] tw-text-[#828282]">积分</p>           
      </div>
      <!-- 簽到日期 -->
      <div class="calendar">
        <div v-for="day in daysInMonth" :key="day" class="calendar__day tw-text-xs sm:tw-text-base" 
          :class="isSignedIn(day) ? 'tw-bg-gradient-to-r tw-from-[#FF5D00] tw-via-[#FE7D05] tw-to-[#FF9606] tw-text-white' : ''">
          <p>{{ day }}</p>
        </div>
      </div>
      <div class="sign__in">
        <div @click="UserSignIn()" :class="!isTodaySignedIn ? 'tw-bg-[#FF6C18]': ''">
          <p v-if="!isTodaySignedIn">立即签到</p>
          <p v-else>今日已签到</p>
        </div>
      </div>
      <div class="remark1 tw-text-[#828282]">
        <p>连续签到7天后，每日可获得最高</p>
        <p class="tw-text-[#FF6802]">7</p>
        <p>积分</p>
      </div>
      <div class="remark2" v-html="signData.remark"></div>
    </div>
  </div>
</template>
  <script>
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "DailyCheckin",
    components: {
      
    },
    props: {

    },
    data() {
      return {
        isPopupDailyCheckin: false,
        isPopupDailyBanner: false,
        currentMonth: '',
        daysInMonth: [],
        totalDaysInMonth: 0,
        signedInDates: [],
        isTodaySignedIn: false,
      };
    },
    computed: {
      ...mapState("users", ["signData", "signCheck", "usersData"]),
    },
    watch: {

    },
    methods: {
      ...mapActions("users", ["getUserSignInRecord", "postUserSignIn"]),
      childClick() {
        this.$emit("isPopupDailyCheckin", this.isPopupDailyCheckin);
        this.$emit("isPopupDailyBanner", this.isPopupDailyBanner);
      },
      getDaysInMonth(year, month) {
        return new Array(new Date(year, month, 0).getDate()).fill().map((_, i) => i + 1);
      },
      isSignedIn(day) {
        return this.signedInDates.includes(day);
      },
      checkTodaySignIn() {
        const today = new Date().getDate();
        this.isTodaySignedIn = this.signedInDates.includes(today);
        console.log('this.isTodaySignedIn = ',this.isTodaySignedIn)
        console.log('this.signedInDates = ',this.signedInDates)
      },
      async UserSignIn() {
        await this.postUserSignIn().then((res) => {
          this.showMessage(res.msg);
        });
        this.getUserSignInRecord();
        this.childClick();
      },
      showMessage(message) {
        this.$message({
          message: message,
          iconClass: "x",
          center: true,
          duration: 1000,
          customClass: "error_message",
        });
      },
    },
    async mounted() {
      const date = new Date();
      this.currentMonth = date.getMonth() + 1; // getMonth() 返回的月份是從 0 開始的，所以需要加 1
      this.totalDaysInMonth = new Date(date.getFullYear(), this.currentMonth, 0).getDate();
      this.daysInMonth = this.getDaysInMonth(date.getFullYear(), this.currentMonth);
      await this.getUserSignInRecord();
      this.signedInDates = this.signData.signedInDates;
      this.checkTodaySignIn();
      console.log('this.signData.remark = ',this.signData.remark)
      console.log('this.usersData = ',this.usersData)
    },
  };
  </script>
<style lang="scss" scoped>

.PopupDailyCheckin {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  .container__close {
    padding-top: 4rem;
  }

  .title {
    font-size: 22px;
    color: black;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .sign__in {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    div {
      padding: 0.5rem 1rem;
      background-color: #ABABAB;
      border-radius: 6px;
      cursor: pointer;
      width: 100%;
      color: white;
    }
  }

  .container__title {
    width: 90%;
    
    margin: -3px auto;
  }

  .container__banner {
    width: 300px;
    @media (min-width: 600px) {
      width: 450px;
      height: 150px;
    }
  }

  .container__info {
    width: 90%;
    @media (min-width: 600px) {
      width: 540px;
    }
    height: calc(85% - 151px);
    background-color: var(--white);
    border-radius: 6px;
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
    position: relative;
    // max-width: 414px;
    margin: 0 auto;
    // @media (min-width: 480px) {
    //   max-width: unset;
    // }

    .calendar {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 0.25rem; /* 設定日期之間的間距，縮小一半 */
      row-gap: 1rem; /* 設定每一行之間的間距 */
      margin-bottom: 1rem;
    }

    .calendar__day {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem; /* 縮小 padding */
      background-color: #EDEFF1;
      border-radius: 50%; /* 確保元素為圓形 */
      aspect-ratio: 1 / 1; /* 確保寬度和高度相同 */
      width: 50%; /* 確保寬度填滿格子，縮小一半 */
      height: 100%; 
      margin: auto;
    }

    .continue__check {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }

    .remark1 {
      display: flex;
      justify-content: center;
      p {
        margin-bottom: 0.5rem;
        text-align: center; /* 讓 p 元素靠右對齊 */
      }
    }

    .remark2 {
      margin-top: 1rem;
      text-align: left; /* 確保文字靠左對齊 */
      display: block; /* 確保這裡使用標準的 block 行為而非 flex */
    }

    .wrap__close {
      position: absolute;
      right: 0.6rem;
      top: 0.4rem;
      width: 1.5rem;

      img {
        width: 100%;
      }
    }
  }
}
</style>
  