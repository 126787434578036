var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"integralExchange"},[_c('Header',{attrs:{"hideGoBack":true,"title":_vm.title,"theme":"light"}}),_c('div',{staticClass:"container__main"},[_vm._l((_vm.productsData),function(item,i){return _c('div',{key:i,staticClass:"wrap__content",on:{"click":function($event){return _vm.$router.push({
          path: '/integralExchangeConfirm',
          query: {
            cardQuota: item['cardQuota'],
            price: item['price'],
            id: item['id']
          },
        })}}},[(i % 2 != 1)?_c('div',{staticClass:"box__img"},[_c('img',{attrs:{"src":require("../../assets/images/public/ic_points_mall_orange.webp"),"alt":""}})]):_c('div',{staticClass:"box__img"},[_c('img',{attrs:{"src":require("../../assets/images/public/ic_points_mall_red.webp"),"alt":""}})]),_c('div',{staticClass:"box__text"},[_c('div',[_vm._v("¥")]),_c('div',[_vm._v(_vm._s(item.cardQuota))]),_c('div',[_vm._v(_vm._s(item.price)+"积分兑换")])])])}),(_vm.signData.hasStarted == 1)?_c('div',{staticClass:"sign__in"},[_c('div',{on:{"click":_vm.showDailyBannerHandler}},[_c('p',[_vm._v("立即签到")])])]):_vm._e(),[(_vm.showDailyBanner == true)?_c('div',[_c('DailyCheckin',{on:{"isPopupDailyBanner":_vm.isPopupDailyBanner}})],1):_vm._e()]],2),_c('FooterMenu')],1)}
var staticRenderFns = []

export { render, staticRenderFns }