import * as api from "@/api/users";
import { tim } from '@/utils/tim'
const users = {
  namespaced: true,
  state: () => ({
    usersData: [],
    signData: {},
    signCheck: "",
  }),
  mutations: {
    setUsersData(state, usersData) {
      state.usersData = usersData;
    },
    setSignData(state, signData) {
      state.signData = signData;
    },
    setSignCheck(state, signCheck) {
      state.signCheck = signCheck;
    },
  },
  actions: {

    getUsers({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getUsers(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setUsersData", res.data);
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    postProductsId({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getUsers(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setProductsIdData", res.data)
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    updateUsers({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        api
          .updateUsers(data)
          .then((res) => {
            if (res.code === "ok") {
              dispatch("getUsers");
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    editUserInfo(_, data) {
      let nick = data.nickName || ''
      let avatar = data.avatar || ''

      let promise
      if (nick !== '') {
        promise = tim.updateMyProfile({
          nick: nick,
        });
      }
      if (avatar !== '') {
        promise = tim.updateMyProfile({
          avatar
        });
      }
      promise.then(function (imResponse) {
        console.log('updateMyProfile :', imResponse.data); // 更新资料成功
      }).catch(function (imError) {
        console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
      });
      return new Promise((resolve, reject) => {
        api.editUserInfo(data).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getUserSignInRecord({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .getUserSignInRecord(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setSignData", res.data)
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    postUserSignIn({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .postUserSignIn(data)
          .then((res) => {
            if (res.code === "ok") {
              commit("setSignCheck", res.msg)
            }
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default users;
